/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    h2: "h2"
  }, _provideComponents(), props.components), {StateCta, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "wearing-hearing-aids-regularly-boosts-longevity-study-says",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#wearing-hearing-aids-regularly-boosts-longevity-study-says",
    "aria-label": "wearing hearing aids regularly boosts longevity study says permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Wearing hearing aids regularly boosts longevity, Study says"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Among the approximately ", React.createElement("a", {
    href: "https://www.ncoa.org/adviser/hearing-aids/hearing-loss-america/#:~:text=The%20state%20of%20hearing%20loss,Control%20and%20Prevention%20(CDC)",
    target: "_blank"
  }, " 40 million adults in the United States"), " experiencing hearing loss, the majority forego the use of hearing aids, potentially missing out on more than just improved auditory function."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Research indicates that untreated hearing loss can contribute to increased risks of frailty, falls, social isolation, depression, and cognitive decline. ", React.createElement("a", {
    href: "https://www.hopkinsmedicine.org/health/wellness-and-prevention/the-hidden-risks-of-hearing-loss#:~:text=In%20a%20study%20that%20tracked,more%20likely%20to%20develop%20dementia",
    target: "_blank"
  }, " A study"), " conducted by scientists at Johns Hopkins University even revealed that individuals with mild hearing loss faced a doubled risk of developing dementia."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement("a", {
    href: "https://www.thelancet.com/journals/lanhl/article/PIIS2666-7568(23)00232-5/fulltext",
    target: "_blank"
  }, "A recent study"), " has now discovered a potential link between using hearing aids and extended lifespans. ", React.createElement("a", {
    href: "https://keck.usc.edu/faculty-search/janet-choi/",
    target: "_blank"
  }, "Dr. Janet Choi"), ", an otolaryngologist with Keck Medicine of USC, sought to assess whether the restoration of hearing through hearing aids could correlate with increased life expectancy."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Utilizing data from the National Health and Nutrition Examination Survey, a comprehensive national study, Choi and her team monitored nearly 1,900 adults identified with hearing loss during screenings. Participants provided information about their usage of hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Choi explains, \"The group of patients who were using hearing aids regularly had a ", React.createElement(_components.strong, null, "24% lower risk of mortality"), " compared to the group who never use hearing aids,\" emphasizing that those habitually wearing hearing aids were significantly less likely to experience premature death."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Surprisingly, the researchers found a more substantial difference in mortality risk than anticipated, considering the negative consequences associated with untreated hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Previous studies have outlined the detrimental effects of untreated age-related hearing loss on both physical and mental health. Recent research has also suggested that restoring hearing with hearing aids may slow cognitive decline in high-risk individuals."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Published in The Lancet Healthy Longevity, this new study adds weight to the growing body of evidence supporting the benefits of hearing aids. While the findings do not definitively prove that hearing aids directly lead to longer life, Choi acknowledges the possibility that consistent hearing aid use may be linked to reduced isolation, increased activity, or a decreased risk of falls, all of which could contribute to enhanced longevity. These findings persisted even after accounting for factors such as age, ethnicity, education, and medical history."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Despite these benefits, Choi highlights the surprising fact that only 12% of people with hearing loss wear hearing aids regularly, according to her study. Notably, individuals who possessed hearing aids but did not use them consistently were as likely to face premature death as those who never used them."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Choi, drawing from personal experience, emphasizes the transformative impact hearing aids can have. Born with hearing loss in one ear, she initially resisted using hearing aids due to her good hearing in the other ear. However, as a surgeon, she realized the significant benefits she was missing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Recognizing various barriers to addressing hearing loss, including evaluation and hearing aid costs, Choi encourages individuals to reconsider using their hearing aids, even if they are currently unused. She suggests wearing hearing aids daily for 30 consecutive days to adapt to them, emphasizing the gradual adjustment process and the potential long-term benefits of enhanced hearing."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-hearing-aids-today",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-hearing-aids-today",
    "aria-label": "get hearing aids today permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get hearing aids today"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re one of the 1 of the 40 million Americans suffering from hearing loss in 2024 and you’re feeling ready to improve your hearing and overall wellbeing, don’t wait for better hearing aid coverage from your Medicare plan. Hear.com can help you maximize your current benefits, book an appointment with an audiologist, find the hearing aid that’s right for you, and answer any question you may have about the process, products, financing, and beyond."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To start the process, simply give our friendly experts a call at (786) 526-0602 or find your state below:"), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(LandingPageCta, {
    copy: "Try the latests hearing aids",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
